import {
  Component,
  ChangeDetectorRef,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { DeviceService } from '../../services/device.service';
import { Address } from 'src/app/interfaces/address';
import { MapboxService } from 'src/app/services/mapbox.service';
import { LocationService } from 'src/app/services/location.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IonBackButton,
  IonContent,
  NavController,
  Platform,
} from '@ionic/angular/standalone';
import { ToastService } from 'src/app/services/toast.service';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AreaHelper } from 'src/app/utils/area-helper';
import { UpdateService } from 'src/app/services/update.service';
import { StorageService } from 'src/app/services/storage.service';
import { Project } from 'src/app/interfaces/project';
import { HttpParams } from '@angular/common/http';
import { ProjectService } from 'src/app/services/project.service';
import { WhiteLabelApplicationService } from 'src/app/services/white-label-application.service';
import { Point } from 'geojson';
import { NgClass, NgIf } from '@angular/common';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { MapComponent } from 'src/app/components/map/map.component';
import { InlineSVGModule } from 'ng-inline-svg-2';

@Component({
  selector: 'app-address-create',
  templateUrl: './create.page.html',
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    MapComponent,
    InlineSVGModule,
    TranslateModule,
    IonContent,
    IonBackButton,
    NgClass,
  ],
})
export class AddressCreatePage implements OnInit {
  map: mapboxgl.Map;
  marker: mapboxgl.Marker;
  markers = {};
  displayedMarkers = {};
  mapMarkers: Project[] = [];

  location: mapboxgl.LngLat;
  loading: boolean = true;
  fullScreen: boolean = false;
  isSettings: boolean = false;
  isKeyboardOpen: boolean = false;
  appLanguage: string;
  geocoder: MapboxGeocoder;
  mapError: boolean = false;
  zoomError: boolean = false;
  radius: number;
  isDesktop: boolean;
  address: Address;
  edit: boolean = false;
  touched: boolean = false;
  historicalUpdateRange: number = 0;
  loadingHistoricalUpdates = true;
  showZoomOverlay: boolean = false;
  bounds: mapboxgl.LngLatBounds;
  projects: Project[] = [];

  constructor(
    private mapboxService: MapboxService,
    private locationService: LocationService,
    private navCtrl: NavController,
    private router: Router,
    private whitelabelService: WhiteLabelApplicationService,
    private deviceService: DeviceService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private platform: Platform,
    private updateService: UpdateService,
    private cd: ChangeDetectorRef,
    private storage: StorageService,
    private route: ActivatedRoute,
    private projectService: ProjectService
  ) {
    if (this.router.url.includes('settings')) this.isSettings = true;
  }

  hideOverlay() {
    this.showZoomOverlay = false;
    this.storage.set('hasSeenZoomOverlay', true);
  }

  private buildGeoJSON(project: Project[]): any {
    const base = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
      },
      features: [],
    };

    project.forEach((project, index) =>
      base.features.push({
        type: 'Feature',
        properties: { id: project.id, slug: project.slug },
        geometry: {
          type: 'Point',
          coordinates: [project.locationLong, project.locationLat, 0.0],
        },
      })
    );

    return base;
  }

  loadImage(src): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => resolve(null);
      img.src = src;
      img.crossOrigin = 'Anonymous';
    });
  }

  initMarkers() {
    this.map.addSource('projects', {
      type: 'geojson',
      data: this.buildGeoJSON(this.mapMarkers),
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 60,
    });

    this.map.addLayer(
      {
        id: 'clusters',
        type: 'circle',
        source: 'projects',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#ffffff',
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      },
      'overlay-layer'
    );

    this.map.on('click', 'clusters', (e) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
      });
      const clusterId = features[0].properties.cluster_id;
      (this.map.getSource('projects') as any).getClusterExpansionZoom(
        clusterId,
        (err, zoom) => {
          if (zoom) {
            this.map.easeTo({
              center: (features[0].geometry as any).coordinates,
              zoom,
            });
          }
        }
      );
    });

    this.map.addLayer(
      {
        id: 'cluster-count',
        type: 'symbol',
        source: 'projects',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Open Sans Regular'],
          'text-size': 12,
        },
      },
      'overlay-layer'
    );

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 40;
    canvas.height = 50;

    Promise.all([
      this.loadImage(
        this.whitelabelService.currentWhiteLabelApp?.appIconThumbnails?.small
      ),
      this.loadImage('/assets/icons/marker.svg'),
    ]).then(([foregroundImg, backgroundImg]) => {
      if (backgroundImg) {
        backgroundImg.width = 40;
        backgroundImg.height = 50;
        context.drawImage(
          backgroundImg,
          0,
          0,
          backgroundImg.width,
          backgroundImg.height
        );
      }
      if (foregroundImg) {
        foregroundImg.width = 28;
        foregroundImg.height = 28;
        context.drawImage(
          foregroundImg,
          (backgroundImg.width - foregroundImg.width) / 2,
          6,
          foregroundImg.width,
          foregroundImg.height
        );
      }
      createImageBitmap(canvas).then((imageBitmap) => {
        this.map.addImage('custom-marker', imageBitmap);
        this.map.addLayer(
          {
            id: 'points',
            type: 'symbol',
            source: 'projects',
            filter: ['!', ['has', 'point_count']],
            layout: {
              'icon-image': 'custom-marker',
            },
          },
          'overlay-layer'
        );
      });
    });
  }

  async ngOnInit() {
    this.isDesktop = this.platform.is('desktop');
    this.showZoomOverlay = !(await this.storage.get('hasSeenZoomOverlay'));

    if (!this.mapboxService.isWebGLSupported()) {
      if (!this.isSettings) {
        this.skip();
      }
    }
  }

  skip() {
    this.router.navigate(['onboarding', 'suggestion', 'list'], {
      replaceUrl: true,
    });
  }

  async mapLoaded(map: mapboxgl.Map) {
    this.map = map;
    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.address = await this.deviceService.getAddress(parseInt(id));
      this.location = new mapboxgl.LngLat(
        this.address.locationLong,
        this.address.locationLat
      );
    } else {
      this.location = await this.getLocation();
    }

    const zoom = this.address
      ? AreaHelper.calculateZoomLevel(
          this.address.locationLat,
          this.address.radius / 2
        )
      : 14;

    this.map.setZoom(zoom).setCenter(this.location);
    this.map.on('click', (event) => {
      this.map.jumpTo({
        center: event.lngLat,
        zoom: this.map.getZoom() > 11 ? this.map.getZoom() : 11,
      });
      this.updateCircle(event.lngLat.toArray());
    });

    this.map.on('zoom', () =>
      this.updateCircle(this.map.getCenter().toArray())
    );

    this.map.on('move', () =>
      this.updateCircle(this.map.getCenter().toArray())
    );

    this.updateCircle(this.map.getCenter().toArray());

    this.loading = false;
    setTimeout(() => {
      this.map.resize();
    });

    setTimeout(() => {
      //occasionally it won't work the first time
      this.map.resize();
    }, 500);

    this.ngZone.run(async () => {
      this.bounds = this.map.getBounds();
      this.loadResults();
      this.updateHistoricalUpdateRange(this.map.getCenter().toArray());
    });

    this.map.on('moveend', async (event) => {
      await this.updateHistoricalUpdateRange(this.map.getCenter().toArray());
      this.ngZone.run(async () => {
        if ((<any>event).skipLoad) return;
        this.bounds = this.map.getBounds();
        this.loadResults();
      });
    });

    this.initMarkers();
  }

  private async updateHistoricalUpdateRange(center: number[]) {
    this.loadingHistoricalUpdates = true;

    let count = 0;
    try {
      count = await this.updateService.getHistoricalUpdateCountForArea(
        center,
        this.radius,
        90
      );
    } catch (e) {
      return;
    }

    if (!count) this.historicalUpdateRange = 0;
    else if (count < 4) this.historicalUpdateRange = 1;
    else if (count < 16) this.historicalUpdateRange = 2;
    else if (count < 51) this.historicalUpdateRange = 3;
    else if (count < 90) this.historicalUpdateRange = 4;
    else this.historicalUpdateRange = 5;

    this.loadingHistoricalUpdates = false;
    this.cd.detectChanges();
  }

  updateCircle(center: number[]) {
    if (this.map.getZoom() < 8) {
      this.ngZone.run(() => {
        this.zoomError = true;
      });
      if (this.map.getLayer('overlay-layer'))
        this.map.removeLayer('overlay-layer');
      if (this.map.getLayer('circle-outline'))
        this.map.removeLayer('circle-outline');
      if (this.map.getSource('overlay-source'))
        this.map.removeSource('overlay-source');
      return;
    } else {
      if (this.zoomError) {
        this.ngZone.run(() => {
          this.zoomError = false;
        });
      }
    }

    const mapWidth = document.querySelector(
      '.address__map .mapboxgl-map'
    ).clientWidth;
    const mapHeight = document.querySelector(
      '.address__map .mapboxgl-map'
    ).clientHeight;
    const useValue = mapHeight < mapWidth ? mapHeight : mapWidth;
    const radiusInPx = (useValue * 0.9) / 4;
    let radiusInMeters =
      this.address && !this.touched
        ? this.address.radius
        : AreaHelper.pixelsToMeters(radiusInPx, center, this.map.getZoom());
    if (radiusInMeters < 100) radiusInMeters = 100;
    this.radius = radiusInMeters;
    const overlay = AreaHelper.createGeoJSONOverlay(center, radiusInMeters);

    if (this.map.getSource('overlay-source')) {
      (this.map.getSource('overlay-source') as mapboxgl.GeoJSONSource).setData(
        overlay
      );
    } else {
      this.map.addSource('overlay-source', {
        type: 'geojson',
        data: overlay,
      });

      this.map.addLayer({
        id: 'overlay-layer',
        type: 'fill',
        source: 'overlay-source',
        paint: {
          'fill-color': 'black',
          'fill-opacity': 0.5,
        },
      });

      this.map.addLayer({
        id: 'circle-outline',
        type: 'line',
        source: 'overlay-source',
        paint: {
          'line-color':
            document.documentElement.style.getPropertyValue('--appTint'),
          'line-width': 3,
        },
      });
    }

    this.touched = true;
  }

  async getAddress() {
    const lngLat = this.map.getCenter();
    const result: any = await this.mapboxService.reverseGeocode(lngLat);
    if (result.features.length) {
      let feature = result.features[0];
      let formattedAddress: string;

      if (this.radius < 2500) {
        formattedAddress =
          feature.context?.find((x) => x.id.includes('locality'))?.text ||
          feature.context?.find((x) => x.id.includes('neighborhood'))?.text;
      }
      let place = result.features.find((x) => x.id.includes('place'))?.text;
      if (place && formattedAddress) formattedAddress += ', ' + place;
      else if (place) formattedAddress = place;
      else
        formattedAddress = this.translateService.instant(
          'address.create.unknown'
        );

      let address: Address = {
        formattedAddress: formattedAddress,
        name: formattedAddress,
        locationLat: feature.center[1],
        locationLong: feature.center[0],
        radius: Math.round(this.radius / 100) * 100,
      };

      if (this.address) {
        await this.deviceService.updateAddress(this.address.id, address);
      } else {
        await this.deviceService.saveAddress(address);
      }

      this.ngZone.run(() => {
        this.navCtrl.setDirection('back');
        if (this.isSettings)
          this.router.navigate(['settings', 'address', 'list']);
        else
          this.router.navigate(['onboarding', 'address', 'list'], {
            replaceUrl: true,
          });
      });
    } else {
      this.toastService.show('address.create.address-error');
    }
  }

  setUrlParams() {
    let params = new HttpParams();
    if (this.bounds) {
      params = params
        .set('box[0][lng]', this.bounds.getNorthEast().lng.toString())
        .set('box[0][lat]', this.bounds.getNorthEast().lat.toString())
        .set('box[1][lng]', this.bounds.getSouthWest().lng.toString())
        .set('box[1][lat]', this.bounds.getSouthWest().lat.toString());
    }

    if (!this.location) {
      return params;
    }

    params = params
      .set('location[lat]', this.location.lat)
      .set('location[lng]', this.location.lng);
    return params;
  }

  async loadResults() {
    try {
      let params = new HttpParams();
      if (this.map.getZoom() <= 6.5) {
        params = params.set('zoom', '1');
      } else {
        params = this.setUrlParams();
      }
      params = params.set(
        'wl',
        this.whitelabelService.currentWhiteLabelApp.id.toString()
      );

      const mapData = await this.projectService.getProjectsByLocation(params);
      this.ngZone.run(() => {
        this.mapMarkers = mapData;

        (this.map.getSource('projects') as mapboxgl.GeoJSONSource).setData(
          this.buildGeoJSON(this.mapMarkers)
        );
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getLocation(): Promise<mapboxgl.LngLat> {
    try {
      const location = await this.locationService.getCurrentPosition();
      const coordinates = new mapboxgl.LngLat(
        location.coords.longitude,
        location.coords.latitude
      );
      return coordinates;
    } catch (error) {
      // No location
      this.toastService.show('address.create.location-error');
      return new mapboxgl.LngLat(4.8359897, 52.3546448);
    }
  }

  toggleFullscreen() {
    this.fullScreen = !this.fullScreen;
    setTimeout(() => {
      this.map.resize();
    });
  }
}
